import type { Application } from '../@types/app';
import { FILTERS_NAMES } from '../constants/filters';

import { formatUserPhoneForChecker } from './formatters';

export const getUserPhoneForChecker = (ctx: Application.IKoaContext | Application.ReduxNextPageContext) => {
  let phoneNumber = ctx.cookie?.get()?._PN_;

  const phoneNumberFromStore = ctx?.store?.getState()?.user?.account?.phone_number;
  const phoneNumberFromUserData = ctx.req?.__USER__?.phone_number;

  if (phoneNumberFromUserData) {
    phoneNumber = phoneNumberFromUserData;
  } else if (phoneNumberFromStore) {
    phoneNumber = phoneNumberFromStore;
  }

  if (!phoneNumber) {
    return {};
  }

  return {
    [FILTERS_NAMES.PHONE_NUMBER]: formatUserPhoneForChecker(phoneNumber),
  };
};
