import { Typography } from '@sravni/react-design-system';
import { Button } from '@sravni/react-design-system/lib/Button';
import type { DialogProps } from '@sravni/react-design-system/lib/Dialog';
import { Sheet } from '@sravni/react-design-system/lib/Sheet';

import styles from './styles.module.scss';

interface Props extends DialogProps {
  title: string;
  buttonText?: string;
  onSubmit: VoidFunction;
  isButtonSubmitDisabled?: boolean;
}
const { Heading } = Typography;

export const InputSheet = ({
  children,
  onClose,
  buttonText = 'Готово',
  title,
  onSubmit,
  isButtonSubmitDisabled,
  ...restProps
}: Props) => (
  <Sheet onClose={onClose} {...restProps} closable={false}>
    <Sheet.Header title={<Heading level={4}>{title}</Heading>} />
    <Sheet.Content className={styles.container}>{children}</Sheet.Content>
    <div className={styles.footer}>
      <Button disabled={isButtonSubmitDisabled} onClick={onSubmit} size={60} variant="primary" block>
        {buttonText}
      </Button>
    </div>
  </Sheet>
);
