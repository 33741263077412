import type { List } from '@src/@types/microcredits';
import { getFullUnitCount } from '@src/helpers/duration';
import { MICROCREDITS_AMOUNT_MIN, MICROCREDITS_TERM_MIN } from 'entities/Microcredits/config';

import { isMetadataInvalid } from './isMetadataInvalid';

export const getFilterValues = (metadata: List.IMetadata, initialAmount?: number, initialTerm?: number) => {
  if (!metadata || isMetadataInvalid(metadata)) {
    return {
      _initialAmount: MICROCREDITS_AMOUNT_MIN,
      _initialTerm: MICROCREDITS_TERM_MIN,
      minTerm: MICROCREDITS_TERM_MIN,
      maxTerm: MICROCREDITS_TERM_MIN,
      maxAmount: MICROCREDITS_AMOUNT_MIN,
      minAmount: MICROCREDITS_AMOUNT_MIN,
    };
  }

  const minTerm = Math.max(getFullUnitCount(metadata.minTerm.term, metadata.minTerm.termUnit), MICROCREDITS_TERM_MIN);
  const maxTerm = getFullUnitCount(metadata.maxTerm.term, metadata.maxTerm.termUnit);

  const minAmount = Math.max(metadata.minAmount.amount, MICROCREDITS_AMOUNT_MIN);
  const maxAmount = metadata.maxAmount.amount;

  const _initialAmount = initialAmount || minAmount;
  const _initialTerm = initialTerm || minTerm;

  return {
    _initialAmount,
    _initialTerm,
    minTerm,
    maxTerm,
    maxAmount,
    minAmount,
  };
};
