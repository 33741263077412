import { useCallback } from 'react';

import { Button } from '@sravni/react-design-system';

import { AmountControlV2 } from '@src/components/ui/AmountControlV2';
import { TermControl } from '@src/components/ui/TermControl';
import { PRODUCT_LIST_ANCHOR } from '@src/constants/common';
import { FILTERS_NAMES } from '@src/constants/filters';
import { scrollToElementById } from '@src/helpers/common';
import { formatMoney } from '@src/helpers/formatMoney';
import { roundNearest } from '@src/helpers/numbers';
import { getDaysPluralize } from '@src/helpers/strings';
import { AMOUNT_SLIDER_STEP, SHOW_OFFERS_BUTTON_TEXT } from 'features/FullDeal/config';
import { CurrencySymbol } from 'shared/config/internalization';
import { HelperText } from 'shared/ui/HelperText';

import { ProductListFilterSortPanelDesktopTexts } from './ProductListFilterSortPanelDesktop.config';
import styles from './ProductListFilterSortPanelDesktop.module.scss';

type Props = {
  initialAmount: number;
  initialTerm: number;

  minTerm: number;
  maxTerm: number;
  minAmount: number;
  maxAmount: number;

  onChange: (value: number | string, name: FILTERS_NAMES) => void;
};

export const ProductListFilterSortPanelDesktop = ({
  initialAmount,
  initialTerm,
  minTerm,
  maxTerm,
  minAmount,
  maxAmount,
  onChange,
}: Props) => {
  const handleClickShowOffers = useCallback(() => {
    scrollToElementById(PRODUCT_LIST_ANCHOR);
  }, []);

  const _minAmount = roundNearest(minAmount, AMOUNT_SLIDER_STEP);
  const _maxAmount = roundNearest(maxAmount, AMOUNT_SLIDER_STEP);

  return (
    <div className={styles.wrapper}>
      <div className={styles.filters}>
        <div className={styles.sumField}>
          <AmountControlV2
            name={FILTERS_NAMES.AMOUNT}
            label={ProductListFilterSortPanelDesktopTexts.amountLabel}
            value={initialAmount}
            min={_minAmount}
            max={_maxAmount}
            postfix={CurrencySymbol.RUB}
            onChange={onChange}
            steps={[{ ceilThreshold: _maxAmount, step: AMOUNT_SLIDER_STEP }]}
          />
          <HelperText text={`от ${formatMoney(_minAmount, { withSymbol: false })} до ${formatMoney(_maxAmount)}`} />
        </div>

        <div className={styles.sumField}>
          <div>
            <TermControl
              name={FILTERS_NAMES.TERM}
              label={ProductListFilterSortPanelDesktopTexts.termLabel}
              value={initialTerm}
              onChange={onChange}
              postfix={getDaysPluralize(initialTerm)}
              min={minTerm}
              max={maxTerm}
            />
            <HelperText text={`от ${minTerm} до ${maxTerm} ${getDaysPluralize(maxTerm)}`} />
          </div>
        </div>

        <Button variant="primary" size={52} onClick={handleClickShowOffers} className={styles.button}>
          {SHOW_OFFERS_BUTTON_TEXT}
        </Button>
      </div>
    </div>
  );
};
