export const TERM_BADGE_LIST = [
  {
    label: '7 дней',
    value: 7,
  },
  {
    label: '10 дней',
    value: 10,
  },
  {
    label: '15 дней',
    value: 15,
  },
  {
    label: '30 дней',
    value: 30,
  },
  {
    label: '60 дней',
    value: 60,
  },
  {
    label: '90 дней',
    value: 90,
  },
  {
    label: '110 дней',
    value: 110,
  },
  {
    label: '200 дней',
    value: 200,
  },
  {
    label: '365 дней',
    value: 365,
  },
  {
    label: '450 дней',
    value: 450,
  },
  {
    label: '730 дней',
    value: 730,
  },
];
