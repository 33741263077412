import { FILTERS_NAMES } from '@src/constants/filters';
import { useValidatedValueFromParams } from '@src/hooks/useValidatedValueFromParams';

interface Props {
  initialAmount: number;
  initialTerm: number;

  minTerm: number;
  maxTerm: number;
  minAmount: number;
  maxAmount: number;

  onChange: (value: number, name: FILTERS_NAMES) => void;
}

export const useValidateQueryParams = ({
  initialAmount,
  initialTerm,
  minAmount,
  maxAmount,
  minTerm,
  maxTerm,
  onChange,
}: Props) => {
  useValidatedValueFromParams({
    onChange,
    value: initialAmount,
    min: minAmount,
    max: maxAmount,
    filterName: FILTERS_NAMES.AMOUNT,
  });

  useValidatedValueFromParams({
    onChange,
    value: initialTerm,
    min: minTerm,
    max: maxTerm,
    filterName: FILTERS_NAMES.TERM,
  });
};
