import type { FC } from 'react';
import React, { useCallback, useState } from 'react';

import { useIsMobile } from '@sravni/react-utils';

import type { FILTERS_NAMES } from '@src/constants/filters';
import { useSelectMicroCreditsMetadata } from '@src/reducers/microcredits';

import { getFilterValues, useValidateQueryParams, isMetadataInvalid } from '../lib';

import { ProductListFilterSortPanelDesktop } from './ProductListFilterSortPanelDesktop';
import { ProductListFilterSortPanelMobile } from './ProductListFilterSortPanelMobile';

interface Props {
  sorting: string | undefined;
  initialAmount: number;
  initialTerm: number;

  onChange: (name: FILTERS_NAMES, value: number) => void;
}

export const ProductListFilterSortPanel: FC<Props> = ({ initialAmount, initialTerm, ...props }) => {
  const isMobile = useIsMobile();
  const metadata = useSelectMicroCreditsMetadata();

  // @todo: metadata почему то изменяется при изменении фильтров, быстрым решением было сохранять начальные значения
  const [initialMetadata] = useState(metadata);

  // @todo: надеюсь мы когда нибудь перепишем все onChange на один вид чтобы не нужно было менять аргументы местами
  const validOnChange = useCallback((value: number, name: FILTERS_NAMES) => {
    props.onChange(name, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { _initialAmount, _initialTerm, minTerm, maxTerm, maxAmount, minAmount } = getFilterValues(
    initialMetadata,
    initialAmount,
    initialTerm,
  );

  useValidateQueryParams({
    onChange: validOnChange,
    initialAmount: _initialAmount,
    initialTerm: _initialTerm,
    minTerm,
    maxTerm,
    maxAmount,
    minAmount,
  });

  if (!initialMetadata || isMetadataInvalid(initialMetadata)) {
    return null;
  }

  if (isMobile) {
    return (
      <ProductListFilterSortPanelMobile
        {...props}
        onChange={validOnChange}
        minTerm={minTerm}
        maxTerm={maxTerm}
        maxAmount={maxAmount}
        minAmount={minAmount}
        initialAmount={_initialAmount}
        initialTerm={_initialTerm}
      />
    );
  }

  return (
    <ProductListFilterSortPanelDesktop
      {...props}
      onChange={validOnChange}
      minTerm={minTerm}
      maxTerm={maxTerm}
      maxAmount={maxAmount}
      minAmount={minAmount}
      initialAmount={_initialAmount}
      initialTerm={_initialTerm}
    />
  );
};
