import cn from 'classnames';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { AmountControl } from '@src/components/ui/AmountControlAB';
import { FILTERS_NAMES } from '@src/constants/filters';
import { getActiveSortButtons } from 'entities/Microcredits/lib';
import { CurrencySymbol } from 'shared/config/internalization';

import { AMOUNT_BADGE_LIST, AMOUNT_FIELD_SETTINGS } from './ProductListAmountControls.config';
import styles from './ProductListAmountControls.module.scss';

type Props = {
  amount: number;

  min: number;
  max: number;

  onChange(value: number): void;
};

export const ProductListAmountControls: FC<Props> = ({ amount, min, max, onChange }) => {
  const amountButtons = useMemo(() => getActiveSortButtons(AMOUNT_BADGE_LIST, min, max), [min, max]);

  return (
    <>
      <div>
        <AmountControl
          name={FILTERS_NAMES.AMOUNT}
          label={AMOUNT_FIELD_SETTINGS.label}
          value={amount}
          onChange={onChange}
          postfix={CurrencySymbol.RUB}
          max={max}
          min={min}
        />
      </div>

      <div className={styles.tagContainer}>
        {amountButtons.map(({ label, value }) => (
          <div
            key={value}
            onClick={() => onChange(value)}
            role="button"
            tabIndex={0}
            className={cn(styles.badge, {
              [styles.inactive]: amount !== value,
              [styles.active]: amount === value,
            })}
          >
            {label}
          </div>
        ))}
      </div>
    </>
  );
};
