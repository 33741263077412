export const AMOUNT_BADGE_LIST = [
  {
    label: '3 000',
    value: 3000,
  },
  {
    label: '5 000',
    value: 5000,
  },
  {
    label: '10 000',
    value: 10000,
  },
  {
    label: '12 000',
    value: 12000,
  },
  {
    label: '15 000',
    value: 15000,
  },
  {
    label: '30 000',
    value: 30000,
  },
  {
    label: '60 000',
    value: 60000,
  },
  {
    label: '100 000',
    value: 100000,
  },
  {
    label: '150 000',
    value: 150000,
  },
  {
    label: '250 000',
    value: 250000,
  },
  {
    label: '500 000',
    value: 500000,
  },
  {
    label: '750 000',
    value: 750000,
  },
  {
    label: '1 000 000',
    value: 1000000,
  },
];

export const AMOUNT_FIELD_SETTINGS = {
  label: 'Мне нужно',
  min: 3000,
};
