import { useCallback, useState } from 'react';

import { useBoolean } from '@sravni/react-utils';

import { Currency } from '@src/@types/currency';
import { FILTERS_NAMES } from '@src/constants/filters';
import { SortPanel } from '@src/containers/list/ProductList/SortPanel';
import { sendChangeAmountFilterEvent, sendChangeTermFilterEvent } from '@src/helpers/analyticsEvents';
import { getAmountString } from '@src/helpers/common';
import { getDaysPluralize } from '@src/helpers/strings';
import { ProductListTermControls, ProductListAmountControls } from 'entities/Microcredits/ui';
import { getValidTermWhenUpdateAmount } from 'features/FullDeal/lib';
import { InputSheet } from 'src/components/details/components/InputSheet';

import styles from './ProductListFilterSortPanelMobile.module.scss';
import type { ActiveField } from './types';

type Props = {
  initialAmount: number;
  initialTerm: number;
  sorting: string | undefined;

  minTerm: number;
  maxTerm: number;
  minAmount: number;
  maxAmount: number;

  onChange: (value: number | string, name: FILTERS_NAMES) => void;
};

export const ProductListFilterSortPanelMobile = ({
  initialAmount,
  initialTerm,
  sorting,
  minTerm,
  maxTerm,
  minAmount,
  maxAmount,
  onChange,
}: Props) => {
  const [isVisible, setVisibility] = useBoolean(false);
  const [activeField, setActiveField] = useState<ActiveField>(null);

  const [amount, setAmount] = useState(initialAmount);
  const [term, setTerm] = useState(initialTerm);

  const amountValue = `${getAmountString(initialAmount, Currency.RUB)}`;
  const pluralizeTerm = getDaysPluralize(initialTerm);
  const termValue = `${initialTerm} ${pluralizeTerm}`;
  const sheetTitle = activeField === FILTERS_NAMES.AMOUNT ? 'Сумма займа' : 'Срок займа';
  const isAmountField = activeField === FILTERS_NAMES.AMOUNT;

  const handleSubmit = () => {
    if (isAmountField) {
      onChange(amount, FILTERS_NAMES.AMOUNT);
      sendChangeAmountFilterEvent(amount, 'Витрина');
    } else {
      onChange(term, FILTERS_NAMES.TERM);
      sendChangeTermFilterEvent(term, 'Витрина');
    }

    setVisibility.off();
  };

  const handleChange = useCallback(
    (value: number) => {
      isAmountField ? setAmount(value) : setTerm(value);
    },
    [isAmountField],
  );

  const handleOpenSheet = useCallback(
    (field: FILTERS_NAMES.AMOUNT | FILTERS_NAMES.TERM) => {
      setActiveField(field);
      setVisibility.on();
    },
    [setVisibility],
  );

  const handleCloseSheet = useCallback(() => {
    setActiveField(null);
    setVisibility.off();

    isAmountField ? setAmount(initialAmount) : setTerm(initialTerm);
  }, [initialAmount, initialTerm, isAmountField, setVisibility]);

  return (
    <>
      <div className={styles.mobileHeader}>
        <div className={styles.badgeContainer}>
          <div onClick={() => handleOpenSheet(FILTERS_NAMES.AMOUNT)} className={styles.badge}>
            {amountValue}
          </div>
          <div onClick={() => handleOpenSheet(FILTERS_NAMES.TERM)} className={styles.badge}>
            {termValue}
          </div>
        </div>
        <span className={styles.badge}>
          <SortPanel
            className={styles.sortWrapper}
            onChange={(name, value) => onChange(value, name)}
            sort={sorting}
            hasSortLabel={false}
          />
        </span>
      </div>

      <InputSheet visible={isVisible} onClose={handleCloseSheet} onSubmit={handleSubmit} title={sheetTitle}>
        {isAmountField ? (
          <ProductListAmountControls amount={amount} onChange={handleChange} min={minAmount} max={maxAmount} />
        ) : (
          <ProductListTermControls term={term} onChange={handleChange} min={minTerm} max={maxTerm} />
        )}
      </InputSheet>
    </>
  );
};
