import cn from 'classnames';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { TermControl } from '@src/components/ui/TermControl';
import { FILTERS_NAMES } from '@src/constants/filters';
import { getDaysPluralize } from '@src/helpers/strings';
import { TERM_LABEL } from 'features/FullDeal/config';

import { getActiveSortButtons } from '../../lib';

import { TERM_BADGE_LIST } from './ProductListTermControls.config';
import styles from './ProductListTermControls.module.scss';

type Props = {
  term: number;
  min: number;
  max: number;

  onChange(value: number): void;
};

export const ProductListTermControls: FC<Props> = ({ min, max, term, onChange }) => {
  const termButtons = useMemo(() => getActiveSortButtons(TERM_BADGE_LIST, min, max), [min, max]);

  return (
    <>
      <div>
        <TermControl
          name={FILTERS_NAMES.TERM}
          label={TERM_LABEL}
          value={term}
          onChange={onChange}
          postfix={getDaysPluralize(term)}
          max={max}
          min={min}
        />
      </div>
      <div className={styles.tagContainer}>
        {termButtons.map(({ label, value }) => (
          <div
            key={value}
            onClick={() => onChange(value)}
            className={cn(styles.badge, {
              [styles.inactive]: term !== value,
              [styles.active]: term === value,
            })}
          >
            {label}
          </div>
        ))}
      </div>
    </>
  );
};
