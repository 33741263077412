import { useEffect, useRef } from 'react';

import type { FILTERS_NAMES } from '@src/constants/filters';
import { validateByParams } from '@src/helpers/validate';

type Props = {
  onChange: (value: number, name: string) => void;
  value: number;
  min: number;
  max: number;
  filterName: FILTERS_NAMES;
};

export const useValidatedValueFromParams = ({ onChange, value, min, max, filterName }: Props) => {
  const handleChange = useRef(onChange);

  useEffect(() => {
    const validate = validateByParams({ max });
    const validatedValue = validate(value);

    if (validatedValue !== value || validatedValue < min || validatedValue > max) {
      handleChange.current(Math.min(Math.max(validatedValue, min), max), filterName);
    }
  }, [value, min, max, filterName]);
};
